document.addEventListener("DOMContentLoaded", function() {
// const instructionIcon = document.querySelector(".instructions-icon i");
// const instructionTooltip = document.querySelector(".instruction-tooltip");

// Function to toggle the visibility of the instruction tooltip
// function toggleInstructionTooltip() {
//     instructionTooltip.classList.toggle("show");
// }

// Event listener to toggle the visibility of the instruction tooltip when clicking on the icon
// instructionIcon.addEventListener("click", toggleInstructionTooltip);
// instructionIcon.addEventListener("mouseenter", toggleInstructionTooltip);
// instructionIcon.addEventListener("mouseleave", toggleInstructionTooltip);

document.getElementById('spliter').addEventListener('click',function viewSplitter(event) {
  event.target.parentNode.classList.toggle('active');
  if (event.target.parentNode.classList.contains('active')) {
      document.getElementById('tryon-live-canvas-0').style.display='block';
      document.getElementById('baseCanvasSplitter').style.display='block';
      document.querySelector('.splitter-range-container').style.display = 'block';
      document.querySelector('.splitter-handler').style.display = 'block';
      document.querySelector('#baseCanvasSplitter').style.width = '50%';
      const rangeInputs = document.querySelector('.splitter-range-container input[type="range"]');
      rangeInputs.addEventListener('input', handleInputChange);
  } else {
      document.getElementById('baseCanvasSplitter').style.display='none';
      document.querySelector('.splitter-range-container').style.display = 'none';
      document.querySelector('.splitter-handler').style.display = 'none';
      document.querySelector('#baseCanvasSplitter').style.width = '0';
      document.querySelector('#splitterController').style.backgroundSize = "50% 100%";
      document.querySelector('.splitter-handler').style.left = "48%";
      document.querySelector('#splitterController').value = 50;
  }
});

function handleInputChange(e) {
  let target = e.target
  if (e.target.type !== 'range') {
      target = document.getElementById('splitterController')
  }
  const min = target.min
  const max = target.max
  const val = target.value

  target.style.backgroundSize = (val - min) * 100 / (max - min) + '% 100%'
}


document.getElementById('splitterController').addEventListener('input',function performSplitting(evt) {
  let value = document.getElementById('splitterController').value;
  document.getElementById('baseCanvasSplitter').style.width = value + '%';
  document.querySelector('.splitter-handler').style.left = value - 2 + '%';
});

});

//         // Image URLs
//         const imageUrls = [
//         './img/algoface/models/1.jpg',
//         './img/algoface/models/2.jpg',
//         './img/algoface/models/3.jpg',
//         './img/algoface/models/4.jpg',
//         './img/algoface/models/5.jpg',
//         './img/algoface/models/6.jpg',
//         './img/algoface/models/7.jpg',
//         './img/algoface/models/8.jpg',
//         './img/algoface/models/9.jpg',
//         './img/algoface/models/10.jpg',
//         './img/algoface/models/11.jpg'
// ];

// const openModalBtn = document.getElementById('openModalBtn');
// const modal = document.getElementById('myModal');
// const closeBtn = document.getElementsByClassName('close')[0];
// const imageContainer = document.getElementById('imageContainer');
// const selectedImage = document.getElementById('selectedImage');

// // Function to open the modal
// function openModal() {
// document.getElementById("try-on-container").scrollIntoView({ behavior: 'smooth' });
// modal.style.display = 'block';
// //renderImages();
// }

// // Function to close the modal
// function closeModal() {
// modal.style.display = 'none';
// }

// // Function to render images inside the modal
// function renderImages() {
// imageContainer.innerHTML = '';
// imageUrls.forEach(url => {
//   const img = document.createElement('img');
//   img.src = url;
//   img.className = 'modal-img';
//   img.addEventListener('click', () => selectImage(url));
//   imageContainer.appendChild(img);
// });
// }

// // Function to select an image
// function selectImage(url) {
// selectedImage.src = url;
// closeModal();
// }

// // Event listeners
// openModalBtn.addEventListener('click',function(event){ 
//   event.preventDefault();
//   document.getElementById("scrollto").scrollIntoView({ behavior: 'smooth' });
//   openModal();
// });
// closeBtn.addEventListener('click', closeModal);
// window.addEventListener('click', (event) => {
// if (event.target === modal) {
//   closeModal();
// }
// });


// var slideIndex = 0;
// showSlides();

// function showSlides() {
// var slides = $(".mySlides");
// var currentSlide = slides.eq(slideIndex);
// var nextSlideIndex = (slideIndex + 1) % slides.length;
// var nextSlide = slides.eq(nextSlideIndex);

// currentSlide.addClass("active-img");
// nextSlide.addClass("active-img");

// setTimeout(function() {
//   currentSlide.removeClass("active-img");
//   nextSlide.removeClass("active-img");
//   slideIndex = nextSlideIndex;
//   showSlides();
// }, 1000); 
// }
// var webcamPlaying = false;

// document.getElementById('fileUpload').addEventListener('change', function(event) {
//   var file = event.target.files[0];
//   var reader = new FileReader();
//   var img = document.getElementById('selectedImage');
//   var video = document.getElementById('webcamStream');

//   if (img.style.display !== 'none') {
//     img.style.display = 'none';
//   }
//   if (webcamPlaying) {
//     video.style.display = 'none'; 
//     webcamPlaying = false;
//   }

//   reader.onload = function(e) {
//     img.src = e.target.result;
//     img.style.display = 'inline-block';
//     document.getElementById("try-on-container").scrollIntoView({ behavior: 'smooth' }); 
//   };

//   reader.readAsDataURL(file);
// });

// document.getElementById('showWebcamBtn').addEventListener('click', function(event) {
//   event.preventDefault();

//   navigator.mediaDevices.getUserMedia({ video: true })
//     .then(function(stream) {
//       var video = document.getElementById('webcamStream');
//       var img = document.getElementById('selectedImage');

//       if (img.style.display !== 'none') {
//         img.style.display = 'none'; 
//       }

//       video.srcObject = stream;
//       video.autoplay = true;
//       video.style.display = 'inline-block'; 
//       webcamPlaying = true; 
//       document.getElementById("try-on-container").scrollIntoView({ behavior: 'smooth' });
//     })
//     .catch(function(err) {
//       console.error('Error accessing webcam:', err);
//     });
// });
